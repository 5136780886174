<template>
<!--//app-header-->
<div class="app-wrapper">

    <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
            <div class="container-fluid mt-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="app-card app-card-orders-table shadow-sm mb-5">
                            <div class="app-card-body">

                                <div class="modal fade" id="staticImport" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="staticBackdropLabel">Import Excel</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">

                                                <div class="mb-3">
                                                    <label for="formFile" class="form-label">File Excel</label>
                                                    <input class="form-control pb-0 pt-0" @change="handleExcelUpload( $event )" type="file" id="formFile" style="height: 2rem;">
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary text-white" :class="loadfile?'disabled' : ''" @click="importfilestore">{{loadfile? 'Loading' : 'Upload' }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row g-3 mb-4 ms-2 me-2 align-items-center justify-content-between">
                                    <div class="col-12">
                                        <h1 class="app-page-title mb-0">Data Barang</h1>
                                    </div>
                                    <div class="col-auto ">

                                        <!-- Button Update Stok modal -->

                                        <!-- Modal -->
                                        <div class="modal fade" id="staticStokupdated" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Stok Masuk</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="row g-">
                                                            <div class="col-md-6">
                                                                <label for="inputPassword4" class="form-label">Barcode*</label>
                                                                <input type="text" v-model="barcodestokmasuk" :class="updtmssg.barcode == null ? 'form-control' : 'form-control is-invalid'" id="inputBarcode">
                                                                <div style="color:red;" v-if="updtmssg.barcode">
                                                                    {{ updtmssg.barcode[0] }}
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="inputPassword4" class="form-label">Stok*</label>
                                                                <input type="text" v-model="stokstokmasuk" :class="updtmssg.stok == null ? 'form-control' : 'form-control is-invalid'" id="inputStok">
                                                                <div style="color:red;" v-if="updtmssg.stok">
                                                                    {{ updtmssg.stok[0] }}
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="inputPassword4" class="form-label">Harga Cash</label>
                                                                <input type="text" v-model="hargacashstokmasuk" :class="updtmssg.hargacash == null ? 'form-control' : 'form-control is-invalid'" id="inputHarga1">
                                                                <div style="color:red;" v-if="updtmssg.hargacash">
                                                                    {{ updtmssg.hargacash[0] }}
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="inputPassword4" class="form-label">Harga Hutang</label>
                                                                <input type="text" v-model="hargahutangstokmasuk" :class="updtmssg.hargahutang == null ? 'form-control' : 'form-control is-invalid'" id="inputHarga2">
                                                                <div style="color:red;" v-if="updtmssg.hargahutang">
                                                                    {{ updtmssg.hargahutang[0] }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-primary text-white" @click="updtstokmasuk">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Button trigger modal -->
                                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            Tambah
                                        </button>
                                        <button type="button" class="btn btn-primary text-white ms-2" data-bs-toggle="modal" data-bs-target="#staticStokupdated">
                                            Stok Masuk
                                        </button>

                                        <!-- Modal -->
                                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Tambah Data</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <form class="" @submit.prevent="tambahForm">
                                                        <div class="modal-body">
                                                            <div v-if="tambahSuccess" class="alert alert-success" role="alert">
                                                                Data Berhasil Ditambah
                                                            </div>
                                                            <div v-if="tambahError" class="alert alert-danger" role="alert">
                                                                Data Gagal Ditambah
                                                            </div>
                                                            <div class="row g-3">
                                                                <div class="col-md-12">
                                                                    <label for="inputEmail4" class="form-label">Nama Barang</label>
                                                                    <input type="text" v-model="nama" :class="tambahMessage.namabarang == null ? 'form-control' : 'form-control is-invalid'" id="inputNama">
                                                                    <div style="color:red;" v-if="tambahMessage.namabarang">
                                                                        {{ tambahMessage.namabarang[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Kode Item</label>
                                                                    <input type="text" v-model="kode_item" :class="tambahMessage.kode_item == null ? 'form-control' : 'form-control is-invalid'" id="inputBarcode">
                                                                    <div style="color:red;" v-if="tambahMessage.kode_item">
                                                                        {{ tambahMessage.kode_item[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Barcode</label>
                                                                    <input type="text" v-model="barcode" :class="tambahMessage.barcode == null ? 'form-control' : 'form-control is-invalid'" id="inputBarcode">
                                                                    <div style="color:red;" v-if="tambahMessage.barcode">
                                                                        {{ tambahMessage.barcode[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Stok</label>
                                                                    <input type="text" v-model="stok" :class="tambahMessage.stok == null ? 'form-control' : 'form-control is-invalid'" id="inputStok">
                                                                    <div style="color:red;" v-if="tambahMessage.stok">
                                                                        {{ tambahMessage.stok[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Harga Cash</label>
                                                                    <input type="text" v-model="hargacash" :class="tambahMessage.hargacash == null ? 'form-control' : 'form-control is-invalid'" id="inputHarga1">
                                                                    <div style="color:red;" v-if="tambahMessage.hargacash">
                                                                        {{ tambahMessage.hargacash[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Harga Hutang</label>
                                                                    <input type="text" v-model="hargahutang" :class="tambahMessage.hargahutang == null ? 'form-control' : 'form-control is-invalid'" id="inputHarga2">
                                                                    <div style="color:red;" v-if="tambahMessage.hargahutang">
                                                                        {{ tambahMessage.hargahutang[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Merek</label>
                                                                    <input type="text" v-model="merek" :class="tambahMessage.merek == null ? 'form-control' : 'form-control is-invalid'" id="inputGudang">
                                                                    <div style="color:red;" v-if="tambahMessage.merek">
                                                                        {{ tambahMessage.merek[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Satuan</label>
                                                                    <input type="text" v-model="satuan" :class="tambahMessage.satuan == null ? 'form-control' : 'form-control is-invalid'" id="inputGudang">
                                                                    <div style="color:red;" v-if="tambahMessage.satuan">
                                                                        {{ tambahMessage.satuan[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Jenis</label>
                                                                    <input type="text" v-model="jenis" :class="tambahMessage.jenis == null ? 'form-control' : 'form-control is-invalid'" id="inputGudang">
                                                                    <div style="color:red;" v-if="tambahMessage.jenis">
                                                                        {{ tambahMessage.jenis[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputRak" class="form-label">Rak</label>
                                                                    <input type="text" v-model="rak" :class="tambahMessage.rak == null ? 'form-control' : 'form-control is-invalid'" id="inputRak">
                                                                    <div style="color:red;" v-if="tambahMessage.rak">
                                                                        {{ tambahMessage.rak[0] }}
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4">
                                                                    <label for="inputKategori" class="form-label">Kategori</label>

                                                                    <select id="inputKategori" v-model="kategoriid" :class="tambahMessage.kategoriid == null ? 'form-select form-control pt-1' : 'form-select form-control pt-1 is-invalid'">
                                                                        <option disabled value="">Pilih Salah Satu</option>
                                                                        <option v-for="(kategori, index) in kategori" :key="index" :value="kategori.id">{{ kategori.nama_kategori }}</option>
                                                                    </select>
                                                                    <div style="color:red;" v-if="tambahMessage.kategoriid">
                                                                        {{ tambahMessage.kategoriid[0] }}
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4">
                                                                    <label for="exampleFormControlInputHarga" class="form-label">Diskon</label>
                                                                    <input type="number" :class="tambahMessage.diskon == null ? ' form-control pt-1' : ' form-control pt-1 is-invalid'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="diskon" id="exampleFormControlInputHarga" placeholder="Diskon">
                                                                    <div style="color:red;" v-if="tambahMessage.diskon">
                                                                        {{ tambahMessage.diskon[0] }}
                                                                    </div>
                                                                </div>

                                                                <div class="col-12">
                                                                    <label for="inputAddress" class="form-label">Deskripsi</label>
                                                                    <input type="text" v-model="deskripsi" :class="tambahMessage.kategoriid == null ? 'form-control' : 'form-control  is-invalid'" id="inputAddress">
                                                                    <div style="color:red;" v-if="tambahMessage.deskripsi">
                                                                        {{ tambahMessage.deskripsi[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="formFile" class="form-label">Gambar</label>
                                                                    <input class="form-control pt-0 pb-0" type="file" @change="handleFileUpload( $event )" id="formFile" style="height: 2rem">
                                                                    <p class="mt-2" v-if="preview">Preview</p>
                                                                    <img v-if="preview" :src="preview" class="img-fluid" style="widyh:100px; height:100px" />
                                                                </div>
                                                                <!-- <div>
                                                                    Tipe Harga
                                                                </div>
                                                                <div class="mb-2 ">
                                                                    <a class="btn btn-primary text-white" v-if="!showdetail" @click="showdetails">Tambah Tipe Harga</a>
                                                                    <div class="table-responsive m-2" v-if="rowharga.length >0">
                                                                        <table class="table app-table-hover mb-0 text-center">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th class="cell">No</th>
                                                                                    <th class="cell">Tipe</th>
                                                                                    <th class="cell">Minimal Beli</th>
                                                                                    <th class="cell">Diskon</th>
                                                                                    <th class="cell">Aksi</th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody style="border-top: 1px solid grey" v-if="loading === false">

                                                                                <tr v-for="(row, index) in rowharga" :key="index">
                                                                                    <td class="cell">{{ index +1 }}</td>
                                                                                    <td class="cell">{{ row.nama_tipe }}</td>
                                                                                    <td class="cell">{{ row.min_jumlah }}</td>
                                                                                    <td class="cell">{{ row.harga}}%</td>
                                                                                    <td>
                                                                                        <a class="btn btn-danger text-white" @click="deleteindextipeharga(index)">Hapus</a>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    <a class="btn btn-primary text-white" v-if="showdetail" @click="showdetails">Tutup Tipe Harga</a>
                                                                </div>
                                                                <div class="col-12 p-2" v-if="showdetail" style="border:2px solid #e7e9ed;">
                                                                    <div class="mb-3">
                                                                        <label for="exampleFormControlInputTipe" class="form-label">Nama Tipe Harga</label>
                                                                        <input type="text" class="form-control" v-model="tipeharga.nama_tipe" id="exampleFormControlInputTipe" placeholder="Nama Tipe Harga">
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <label for="exampleFormControlInputMin" class="form-label">Minimal Beli</label>
                                                                        <input type="number" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="tipeharga.min_jumlah" id="exampleFormControlInputMin" placeholder="Minimal Beli">
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <label for="exampleFormControlInputHarga" class="form-label">Diskon</label>
                                                                        <input type="number" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="tipeharga.harga" id="exampleFormControlInputHarga" placeholder="Harga">
                                                                    </div>
                                                                    <div v-if="tipeharga.nama_tipe!=null && tipeharga.min_jumlah!=null && tipeharga.harga!=0">
                                                                        <button type="button" class="btn btn-primary text-white" @click="simpantipeharga">Simpan Tipe Harga</button>
                                                                    </div>
                                                                    <div v-else>
                                                                        <button type="button" class="btn btn-primary text-white" disabled>Simpan Tipe Harga</button>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer" style="border-top:0px">
                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                            <button type="submit" class="btn btn-primary text-white">Save</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Modal Edit-->
                                        <div class="modal fade" :id="'staticEdit'" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Edit</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="setClose"></button>
                                                    </div>
                                                    <form action="" @submit.prevent="editForm">
                                                        <div class="modal-body">
                                                            <div v-if="tambaheditSuccess" class="alert alert-success" role="alert">
                                                                Data Berhasil Ditambah
                                                            </div>
                                                            <div v-if="tambaheditError" class="alert alert-danger" role="alert">
                                                                Data Gagal Ditambah
                                                            </div>

                                                            <div class="row g-3">

                                                                <div class="col-md-12">
                                                                    <label for="inputEmail4" class="form-label">Nama Barang</label>
                                                                    <input type="text" v-model="namaEdit" :class="editMessage.namabarang == null ? 'form-control' : 'form-control is-invalid'" id="inputNama">
                                                                    <div style="color:red;" v-if="editMessage.namabarang">
                                                                        {{ editMessage.namabarang[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Kode Item</label>
                                                                    <input type="text" v-model="kode_itemEdit" :class="editMessage.kode_item == null ? 'form-control' : 'form-control is-invalid'" id="inputBarcode">
                                                                    <div style="color:red;" v-if="editMessage.kode_item">
                                                                        {{ editMessage.kode_item[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Barcode</label>
                                                                    <input type="text" v-model="barcodeEdit" :class="editMessage.barcode == null ? 'form-control' : 'form-control is-invalid'" id="inputBarcode">
                                                                    <div style="color:red;" v-if="editMessage.barcode">
                                                                        {{ editMessage.barcode[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Stok</label>
                                                                    <input type="text" v-model="stokEdit" :class="editMessage.stok == null ? 'form-control' : 'form-control is-invalid'" id="inputStok">
                                                                    <div style="color:red;" v-if="editMessage.stok">
                                                                        {{ editMessage.stok[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Harga Cash</label>
                                                                    <input type="text" v-model="hargacashEdit" :class="editMessage.harga_cash == null ? 'form-control' : 'form-control is-invalid'" id="inputHarga1">
                                                                    <div style="color:red;" v-if="editMessage.harga_cash">
                                                                        {{ editMessage.harga_cash[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Harga Hutang</label>
                                                                    <input type="text" v-model="hargahutangEdit" :class="editMessage.harga_hutang == null ? 'form-control' : 'form-control is-invalid'" id="inputHarga2">
                                                                    <div style="color:red;" v-if="editMessage.harga_hutang">
                                                                        {{ editMessage.harga_hutang[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Merek</label>
                                                                    <input type="text" v-model="merekEdit" :class="editMessage.merek == null ? 'form-control' : 'form-control is-invalid'" id="inputGudang">
                                                                    <div style="color:red;" v-if="editMessage.merek">
                                                                        {{ editMessage.merek[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Satuan</label>
                                                                    <input type="text" v-model="satuanEdit" :class="editMessage.satuan == null ? 'form-control' : 'form-control is-invalid'" id="inputGudang">
                                                                    <div style="color:red;" v-if="editMessage.satuan">
                                                                        {{ editMessage.satuan[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputPassword4" class="form-label">Jenis</label>
                                                                    <input type="text" v-model="jenisEdit" :class="editMessage.jenis == null ? 'form-control' : 'form-control is-invalid'" id="inputGudang">
                                                                    <div style="color:red;" v-if="editMessage.jenis">
                                                                        {{ editMessage.jenis[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="inputRak" class="form-label">Rak</label>
                                                                    <input type="text" v-model="rakEdit" :class="editMessage.rak == null ? 'form-control' : 'form-control is-invalid'" id="inputRak">
                                                                    <div style="color:red;" v-if="editMessage.rak">
                                                                        {{ editMessage.rak[0] }}
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4">
                                                                    <label for="inputKategori" class="form-label">Kategori</label>

                                                                    <select id="inputKategori" v-model="kategoriidEdit" :class="editMessage.kategoriid == null ? 'form-select form-control pt-1' : 'form-select form-control pt-1 is-invalid'">
                                                                        <option disabled value="">Pilih Salah Satu</option>
                                                                        <option v-for="(kategori, index) in kategori" :key="index" :value="kategori.id">{{ kategori.nama_kategori }}</option>
                                                                    </select>
                                                                    <div style="color:red;" v-if="editMessage.kategoriid">
                                                                        {{ editMessage.kategoriid[0] }}
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4">
                                                                    <label for="exampleFormControlInputHarga" class="form-label">Diskon</label>
                                                                    <input type="number" :class="editMessage.diskon == null ? ' form-control pt-1' : ' form-control pt-1 is-invalid'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="diskonEdit" id="exampleFormControlInputHarga" placeholder="Diskon">
                                                                    <div style="color:red;" v-if="editMessage.diskon">
                                                                        {{ editMessage.diskon[0] }}
                                                                    </div>
                                                                </div>

                                                                <div class="col-12">
                                                                    <label for="inputAddress" class="form-label">Deskripsi</label>
                                                                    <input type="text" v-model="deskripsiEdit" :class="editMessage.kategoriid == null ? 'form-control' : 'form-control  is-invalid'" id="inputAddress">
                                                                    <div style="color:red;" v-if="editMessage.deskripsi">
                                                                        {{ editMessage.deskripsi[0] }}
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="formFile" class="form-label">Gambar</label>
                                                                    <input class="form-control pt-0 pb-0" type="file" @change="handleFileUploadEdit( $event )" id="formFile" style="height: 2rem">
                                                                    <p class="mt-2" v-if="previewEdit">Preview</p>
                                                                    <img v-if="previewEdit" :src="previewEdit" class="img-fluid" style="widyh:100px; height:100px" />
                                                                    <div v-if="edited" class="mt-2">
                                                                        <img v-if="previewEdit==''&&newfoto!=''" class="img-fluid" v-bind:src="link+'storage/gambar/' + nowfoto" style="width: 100px; height: 100px" />
                                                                    </div>
                                                                </div>
                                                                <table class="table app-table-hover mb-0 text-center mt-2" v-if="rowhargaEdit.length >0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="cell">Nama Tipe</th>
                                                                            <th class="cell">Min Jumlah</th>
                                                                            <th class="cell">Diskon</th>
                                                                            <th class="cell">Aksi</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style="" v-if="loading === false">
                                                                        <tr v-for="(post2, index) in rowhargaEdit" :key="index">
                                                                            <td class="cell">{{post2.nama_tipe}}</td>
                                                                            <td class="cell">{{post2.min_jumlah}}</td>
                                                                            <td class="cell">{{post2.harga}}%</td>
                                                                            <th class="cell">
                                                                                <a class="btn btn-sm btn-danger text-white" @click="deleteindextipehargaedit(index)">Hapus</a>
                                                                            </th>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                                <!-- <div class="text-center mt-3">
                                                                    <a class="btn btn-sm btn-primary text-white" @click="showdetailtipeeditsw"><a v-if="showdetailtipeedit">Tutup</a> <a v-else>Tampilkan</a> Input Tipe Harga</a>
                                                                </div>
                                                                <div class="col-12" v-if="showdetailtipeedit">
                                                                    <div class="mb-3">
                                                                        <label for="exampleFormControlInputTipe" class="form-label">Nama Tipe Harga</label>
                                                                        <input type="text" class="form-control" v-model="tipehargaEdit.nama_tipe" id="exampleFormControlInputTipe" placeholder="Nama Tipe Harga">
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <label for="exampleFormControlInputMin" class="form-label">Minimal Beli</label>
                                                                        <input type="number" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="tipehargaEdit.min_jumlah" id="exampleFormControlInputMin" placeholder="Minimal Beli">
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <label for="exampleFormControlInputHarga" class="form-label">Diskon</label>
                                                                        <input type="number" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="tipehargaEdit.harga" id="exampleFormControlInputHarga" placeholder="Harga">
                                                                    </div>
                                                                    <div v-if="tipehargaEdit.nama_tipe!=null && tipehargaEdit.min_jumlah!=null && tipehargaEdit.harga!=0">
                                                                        <button type="button" class="btn btn-primary text-white" @click="simpantipehargaedit">Simpan Tipe Harga</button>
                                                                    </div>
                                                                    <div v-else>
                                                                        <button type="button" class="btn btn-primary text-white" disabled>Simpan Tipe Harga</button>
                                                                    </div>
                                                                </div> -->

                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="setClose">Close</button>
                                                            <button type="submit" class="btn btn-primary">Save</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Button trigger modal -->

                                        <!-- Modal Barcode-->
                                        <div class="modal fade" id="staticPrintbarcode" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Print Barcode</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="mb-2">
                                                            Kode Barkode : <b>{{printBarcode}}</b><br>
                                                            Kode Item : <b>{{printItem}}</b><br>
                                                        </div>
                                                        <div class="mb-2" >
                                                            Pilih Jenis Kode 
                                                            <select class="form-control" name="kode" v-model="jnskode" id="kode">
                                                                <option value="0">Kode Barcode</option>
                                                                <option value="1">Kode Item</option>
                                                            </select>
                                                        </div>
                                                        Pilih Jumlah
                                                        <input type="number" class="form-control mt-2" v-model="totalLabel" id="exampleFormControlInput1" placeholder="Total Label">
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-primary text-white" @click="printLabelbutton">Print</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="page-utilities">
                                            <div class="row g-2 justify-content-start justify-content-md-end align-items-center">
                                                <div class="col-auto">
                                                    <div class="docs-search-form row gx-1 align-items-center">

                                                        <div class="col-8 col-md-auto mt-2 mt-md-0">

                                                            <div class="input-group ">
                                                                <input v-on:keyup.enter="getSearch" v-model="search" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Search">
                                                                <button @click="getReset" class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> X </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 col-md-auto mt-2 mt-md-0">
                                                            <button @click="getSearch" type="submit" class="btn app-btn-secondary w-100" style="height:40px">Search</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <!--//col-->

                                                <div class="col-auto">
                                                    <a class="btn app-btn-primary" data-bs-toggle="modal" data-bs-target="#staticImport"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-upload me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                            <path fill-rule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                                        </svg>Import Excel</a>
                                                </div>
                                            </div>
                                            <!--//row-->
                                        </div>
                                        <!--//table-utilities-->
                                    </div>
                                    <!--//col-auto-->
                                    <div v-if="tambahdeleteSuccess" class="alert alert-success" role="alert">
                                        Data Berhasil Dihapus
                                    </div>
                                    <div v-if="tambahdeleteError" class="alert alert-danger" role="alert">
                                        Data Gagal Dihapus, data barang sudah memiliki transaksi
                                    </div>
                                </div>
                                <!--//row-->

                                <div class="table-responsive m-2">
                                    <table class="table app-table-hover mb-0 text-center">
                                        <thead>
                                            <tr>
                                                <th class="cell">No</th>
                                                <th class="cell">Foto</th>
                                                <th class="cell">Nama Barang</th>
                                                <th class="cell">Kantor Cabang</th>
                                                <th class="cell">Kode Item</th>
                                                <th class="cell">Kode Barcode</th>
                                                <th class="cell">Diskon</th>
                                                <th class="cell">Harga</th>
                                                <th class="cell">Stok</th>
                                                <th class="cell">Penyimpanan</th>
                                                <th class="cell">Aksi</th>
                                            </tr>
                                        </thead>

                                        <tbody style="border-top: 1px solid grey" v-if="loading === false">

                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td class="cell">{{ index +1 }}</td>
                                                <td class="cell"><img v-bind:src="link+'storage/gambar/' + post.gambar" style="width: 50px; height: 50px" /> </td>
                                                <td class="cell">{{ post.nama_barang }}</td>
                                                <td class="cell">{{ post.kantor_cabang.nama_cabang }}</td>
                                                <td class="cell">
                                                    <vue-barcode :value=" post.kode_item " :option="{format:'code128'}" style="width:120px" tag="img"></vue-barcode>

                                                </td>
                                                <td class="cell">
                                                    <vue-barcode :value=" post.kode_barcode " :option="{format:'code128'}" style="width:120px" tag="img"></vue-barcode>

                                                </td>
                                                <td class="cell">{{ post.diskon}}%</td>
                                                <td class="cell">
                                                    <s style="color:red" v-if="post.diskon!=0">{{ "Rp" + post.harga_cash.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</s>
                                                    {{ "Rp" + (post.harga_cash-(post.harga_cash*post.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                    <br>
                                                    <s style="color:red" v-if="post.diskon!=0">{{ "Rp" + post.harga_hutang.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</s>
                                                    {{ "Rp" + (post.harga_hutang-(post.harga_hutang*post.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                </td>
                                                <td class="cell">{{ post.stok }}</td>
                                                <td class="cell">Rak ke- {{ post.rak }}</td>
                                                <td class="text-center cell">
                                                    <!-- Button trigger modal -->
                                                    <button type="button" class="btn btn-primary text-white btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#staticSee'+index">
                                                        Detail
                                                    </button>

                                                    <!-- Modal -->
                                                    <div class="modal fade" :id="'staticSee' + index" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                        <div class="modal-dialog modal-lg">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="staticBackdropLabel">Detail</h5>
                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="table-responsive m-2">
                                                                        <table class="table app-table-hover mb-0 text-center">
                                                                            <tbody style="border-top: 1px solid grey" v-if="loading === false">
                                                                                <tr>
                                                                                    <td class="cell">Nama Barang</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{post.nama_barang}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Cabang</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{post.kantor_cabang.nama_cabang}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Kode Item</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">
                                                                                        <vue-barcode :value=" post.kode_item " style="width:120px" tag="img"></vue-barcode>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Kode Barcode</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">
                                                                                        <vue-barcode :value=" post.kode_barcode " style="width:120px" tag="img"></vue-barcode>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Kategori</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell"><a v-if="post.kategori">{{post.kategori.nama_kategori}}</a></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Diskon</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{ post.diskon}}%</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Harga Cash</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell"> <s style="color:red" v-if="post.diskon!=0">{{ "Rp" + post.harga_cash.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</s>
                                                                                        {{ "Rp" + (post.harga_cash-(post.harga_cash*post.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Harga Hutang</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell"> <s style="color:red" v-if="post.diskon!=0">{{ "Rp" + post.harga_hutang.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</s>
                                                                                        {{ "Rp" + (post.harga_hutang-(post.harga_hutang*post.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Stok</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{ post.stok}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Deskripsi</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{ post.deskripsi}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Tempat Penyimpanan</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">Rak ke- {{ post.rak}}</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                        <!-- <div class="text-center mt-3" v-if="post.tipe_harga.length>0">
                                                                            <button class="btn btn-sm btn-primary text-white" @click="showdetailtipes"><a v-if="showdetailtipe">Tutup</a> <a v-else>Tampilkan</a> Tipe Harga</button>
                                                                        </div>
                                                                        <table class="table app-table-hover mb-0  mt-2" v-if="showdetailtipe">
                                                                            <thead>
                                                                                <tr>

                                                                                    <th class="cell">Nama Tipe</th>
                                                                                    <th class="cell">Min Jumlah</th>
                                                                                    <th class="cell">Diskon</th>
                                                                                    <th class="cell">Harga</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style="" v-if="loading === false">
                                                                                <tr v-for="(post2, index) in post.tipe_harga" :key="index">

                                                                                    <td class="cell text-center">{{post2.nama_tipe}}</td>
                                                                                    <td class="cell text-center">{{post2.min_jumlah}}</td>
                                                                                    <td class="cell text-center">{{post2.harga}}%</td>
                                                                                    <td class="cell text-left">Harga Cash : {{ "Rp " +(post.harga_cash- (post.harga_cash * (post2.harga+post.diskon)/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}} <br>
                                                                                        Harga Hutang : {{ "Rp " + (post.harga_hutang-(post.harga_hutang * (post2.harga+post.diskon)/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table> -->
                                                                    </div>

                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-primary text-white" data-bs-dismiss="modal">Oke</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn btn-warning text-white btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#staticEdit'" @click="setEdit(index)">
                                                        Edit
                                                    </button>
                                                    <button type="button" class="btn btn-secondary text-white btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#staticPrintbarcode'" @click="setBarcode(index)">
                                                        Print Barkode
                                                    </button>

                                                    <!-- <router-link
                                  :to="{ name: 'post.edit', params: { id: post.id } }"
                                  class="btn btn-sm btn-primary me-2"
                                  >EDIT</router-link
                                > -->
                                                    <button class="btn btn-sm btn-danger text-white" @click="deleteForm(post.id)">DELETE</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody style="border-top: 1px solid grey" v-else>
                                            <tr>
                                                <td colspan="9" class="text-center " style="height: 200px; padding-top: 100px;">
                                                    <h3> Loading....</h3>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- <Pagination :data="laravelData" @pagination-change-page="getResults" /> -->

                                    <nav class="app-pagination mt-3">
                                        <ul class="pagination justify-content-center">

                                            <li :class="[pagination.prev_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.prev_page_url)">Previous</a></li>
                                            <li v-if="pagination.current_page !=1 && pagination.current_page !=2" :class="[pagination.current_page == 1 ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClick(1)">1</a></li>
                                            <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] " v-for="item =1 in pagination.last_page" :key="item">
                                                <a class="page-link" v-if="Math.abs(item -1 - pagination.current_page) < 3 || item -1 == pagination.total - 1 " href="#" @click="onClick(item)" :class="{current: pagination.current_page === item-1, last: (item -1== pagination.total - 1 && Math.abs(item -1- pagination.current_page) > 3), first:(item -1== 0 && Math.abs(item -1- pagination.current_page) > 3)}">{{ item+1-1 }}</a>
                                            </li>
                                            <li v-if="pagination.current_page !=pagination.last_page && pagination.current_page <pagination.last_page-5 " :class="[pagination.current_page == pagination.last_page ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClick(pagination.last_page)">{{pagination.last_page}}</a></li>
                                            <!-- <li v-if="pagination.current_page !=pagination.last_page && pagination.current_page <pagination.last_page-3 " :class="[pagination.current_page == pagination.last_page ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClick(pagination.last_page)">{{pagination.last_page}}</a></li> -->
                                            <!-- <div v-for="item =1 in pagination.last_page" :key="item">
                                                <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] "><a class="page-link" @click="onClick(item)">{{item}}</a></li>
                                            </div> -->
                                            <li class="page-item" :class="[pagination.next_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.next_page_url)">Next</a></li>
                                        </ul>
                                    </nav>

                                    <!--//app-pagination-->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

// import { onMounted, ref } from 'vue'

export default {

    computed: {
        totalPages: function () {
            return Math.ceil(this.pagination.total / this.pagination.per_page)
        }
    },
    data() {
        return {
            // Our data object that holds the Laravel paginator data
            posts: {},
            page: 1,
            pagination: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            stokstokmasuk: '',
            barcodestokmasuk: '',
            hargacashstokmasuk: '',
            hargahutangstokmasuk: '',
            importMessage: [],
            kantorcabangidImport: '',
            link: this.globalApiUrl,
            loading: false,
            loggedIn: localStorage.getItem('loggedIn'),
            token: localStorage.getItem('token'),
            search: "",
            searchCabang: localStorage.getItem('cabang'),
            importfile: undefined,
            // tambah : [],

            kantorcabangid: '',
            kategoriid: '',
            supplierid: '',
            nama: '',
            deskripsi: '',
            kode_item: '',
            barcode: '',
            hargacash: '',
            hargahutang: '',
            stok: '',
            diskon: 0,
            merek: '',
            satuan: '',
            jenis: '',
            rak: '',
            file: undefined,

            cabang: [],
            kategori: [],
            supplier: [],
            urlImage: null,
            preview: null,
            // tmbhModal: null,
            tambahSuccess: false,
            tambahError: false,
            tambaheditSuccess: false,
            tambaheditError: false,
            tambahdeleteSuccess: false,
            tambahdeleteError: false,
            tambahMessage: [],
            editMessage: [],
            updtmssg: [],

            idEdit: '',
            kantorcabangidEdit: '',
            kategoriidEdit: null,
            supplieridEdit: '',
            namaEdit: '',
            deskripsiEdit: '',
            kode_itemEdit: '',
            barcodeEdit: '',
            hargacashEdit: '',
            hargahutangEdit: '',
            stokEdit: '',
            merekEdit: '',
            satuanEdit: '',
            jenisEdit: '',
            rakEdit: null,
            diskonEdit: 0,
            fileEdit: undefined,
            previewEdit: '',
            nowfoto: '',
            edited: false,
            loadfile: false,

            showdetailtipe: false,
            showdetailtipeedit: false,

            showdetail: false,
            tipeharga: {
                'nama_tipe': null,
                'min_jumlah': null,
                'harga': 0,
            },
            rowharga: [],
            tipehargaEdit: {
                'nama_tipe': null,
                'min_jumlah': null,
                'harga': 0,
            },
            rowhargaEdit: [],
            laravelData: {},
            printBarcode: '',
            printItem: '',
            printNama: '',
            totalLabel:0,
            jnskode:0,
        }
    },

    created() {
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        } else {
            this.getResults();

            this.getKategori();
            // this.getSupplier();
        }

    },

    methods: {
        handleFileUpload(event) {
            this.file = event.target.files[0];
            var input = event.target;
            console.log(input.files);
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        handleExcelUpload(event) {
            this.importfile = event.target.files[0];

        },
        importfilestore() {
            var form = new FormData();
            this.loadfile = true;
            if (this.importfile) {
                form.append('filebarang', this.importfile);
            }
            form.append('cabang', this.searchCabang);
            axios.post(this.link + 'api/barang-import', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambaheditSuccess = true;
                this.$toast.show(`Data Berhasil Diupload`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.loadfile = false;
                this.$nextTick(function () {
                    $('#staticImport').modal('hide');
                });
                this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambaheditError = true;
                this.$toast.show(`Data Gagal Diupload`, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.loadfile = false;
                // // if()
                // this.editMessage = error.response.data.data.error;
                console.log(this.editMessage);
            })
        },
        handleFileUploadEdit(event) {
            this.fileEdit = event.target.files[0];
            var input = event.target;
            console.log(input.files);
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.previewEdit = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        setClose() {
            this.edited = false;
            this.fileEdit = undefined;
            this.previewEdit = '';
        },
        async setEdit(edit) {
            this.tambaheditSuccess = false;
            this.tambaheditError = false;
            this.editMessage = [];
            this.edited = true;
            this.idEdit = this.posts[edit].id;
            // this.kantorcabangidEdit = this.posts[edit].kantor_cabang_id;
            this.kategoriidEdit = this.posts[edit].kategori_id;
            this.supplieridEdit = this.posts[edit].supplier_id;
            this.namaEdit = this.posts[edit].nama_barang;
            this.deskripsiEdit = this.posts[edit].deskripsi == null ? '' : this.posts[edit].deskripsi;
            this.kode_itemEdit = this.posts[edit].kode_item;
            this.barcodeEdit = this.posts[edit].kode_barcode;
            this.hargacashEdit = this.posts[edit].harga_cash;
            this.hargahutangEdit = this.posts[edit].harga_hutang;
            this.stokEdit = this.posts[edit].stok;
            this.merekEdit = this.posts[edit].merek == null ? '' : this.posts[edit].merek;
            this.satuanEdit = this.posts[edit].satuan == null ? '' : this.posts[edit].satuan;
            this.jenisEdit = this.posts[edit].jenis == null ? '' : this.posts[edit].jenis;
            this.rakEdit = this.posts[edit].rak == null ? '' : this.posts[edit].rak;
            this.nowfoto = this.posts[edit].gambar;
            this.rowhargaEdit = this.posts[edit].tipe_harga,
                console.log(this.posts[edit]);
        },
        async setBarcode(index) {
            this.printBarcode = this.posts[index].kode_barcode;
            this.printNama = this.posts[index].nama_barang;
            this.printItem = this.posts[index].kode_item;
            console.log(this.printBarcode);
        },
        editForm() {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.tambaheditSuccess = false;
            this.tambaheditError = false;
            this.editMessage = [];
            var form = new FormData();
            form.append('id', this.idEdit);
            form.append('kantorcabangid', this.searchCabang);
            if (this.kategoriidEdit != null) {
                form.append('kategoriid', this.kategoriidEdit);
            }

            // form.append('supplierid', this.supplieridEdit);
            form.append('namabarang', this.namaEdit);
            form.append('deskripsi', this.deskripsiEdit);
            form.append('kode_item', this.kode_itemEdit);
            form.append('barcode', this.barcodeEdit);
            form.append('hargacash', this.hargacashEdit);
            form.append('hargahutang', this.hargahutangEdit);
            form.append('stok', this.stokEdit);
            form.append('merek', this.merekEdit);
            form.append('satuan', this.satuanEdit);
            form.append('jenis', this.jenisEdit);
            form.append('rak', this.rakEdit);
            form.append('diskon', this.diskonEdit);
            form.append('tipeharga', JSON.stringify(this.rowhargaEdit));
            if (this.fileEdit) {
                form.append('gambar', this.fileEdit);
            }
            axios.post(this.link + 'api/barang-edit', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambaheditSuccess = true;
                this.$toast.show(`Data Berhasil Diupdate`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.$nextTick(function () {
                    $('#staticEdit').modal('hide');
                });
                this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambaheditError = true;
                this.$toast.show(`Data Gagal Diupdate`, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                // if()
                this.editMessage = error.response.data.data.error;
                console.log(this.editMessage);
            })
        },
        updtstokmasuk() {

            var form = new FormData();

            form.append('barcode', this.barcodestokmasuk);
            form.append('hargacash', this.hargacashstokmasuk);
            form.append('hargahutang', this.hargahutangstokmasuk);
            form.append('stok', this.stokstokmasuk);

            axios.post(this.link + 'api/barang-updatestok', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Stok Berhasil Ditambah`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.$nextTick(function () {
                    $('#staticStokupdated').modal('hide');
                });
                this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambahError = true;
                this.$toast.show(`Data Stok Gagal Ditambah`, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                // if()
                this.updtmssg = error.response.data.data.error;

            })
        },
        tambahForm() {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.tambahSuccess = false;
            this.tambahError = false;
            this.tambahMessage = [];
            var form = new FormData();
            form.append('kantorcabangid', this.searchCabang);
            form.append('kategoriid', this.kategoriid);
            // form.append('supplierid', this.supplierid);
            form.append('namabarang', this.nama);
            form.append('deskripsi', this.deskripsi);
            form.append('kode_item', this.kode_item);
            form.append('barcode', this.barcode);
            form.append('hargacash', this.hargacash);
            form.append('hargahutang', this.hargahutang);
            form.append('stok', this.stok);
            form.append('merek', this.merek);
            form.append('satuan', this.satuan);
            form.append('jenis', this.jenis);
            form.append('diskon', this.diskon);
            form.append('rak', this.rak);
            console.log(this.rowharga[1])
            // this.rowharga.forEach((item) => form.append("tipeharga[]", item))
            // for (var i = 0; i < this.rowharga.length; i++) {
            //     form.append('tipeharga[]', this.rowharga[i]);
            // }
            form.append('tipeharga', JSON.stringify(this.rowharga));
            if (this.file) {
                form.append('gambar', this.file);
            }
            console.log(form);

            // if (this.tambah) {

            // console.log(this.token);
            axios.post(this.link + 'api/barang', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Ditambah`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.$nextTick(function () {
                    $('#staticBackdrop').modal('hide');

                });
                this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambahError = true;
                this.$toast.show(`Data Gagal Ditambah`, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                // if()
                this.tambahMessage = error.response.data.data.error;
                console.log(this.tambahMessage);
            })
            // }
        },
        deleteForm($id) {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            var form = new FormData();
            form.append('id', $id);
            axios.post(this.link + 'api/barang-delete', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahdeleteSuccess = true;
                this.$toast.show(`Data Berhasil Dihapus`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambahdeleteError = true;
                this.$toast.show(`Data Gagal Dihapus, data barang sudah memiliki transaksi`, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                // if()

                console.log(this.editMessage);
            })
        },
        getResults() {

            this.loading = true;
            console.log(this.loading);
            axios.get(this.link + 'api/barang?limit=10&name=' + this.search + '&page=' + this.page + '&cabang=' + this.searchCabang)
                .then(response => {

                    this.posts = response.data.data.data;
                    this.pagination = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                }).then(
                    this.loading = false
                );
            console.log(this.loading);
        },
        getKategori() {
            axios.get(this.link + 'api/kategori', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.kategori = response.data.data // assign response to state user
            })
        },
        getSupplier() {
            axios.get(this.link + 'api/supplier-semua', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.supplier = response.data.data // assign response to state user
            })
        },
        getReset() {
            if (this.search != '') {
                this.search = "";
                this.page = 1;
                this.tambahdeleteError = false;
                this.tambahdeleteSuccess = false;
                this.getResults();
            }
        },
        onClick(item) {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.page = item;
            this.getResults();
        },
        getSearch() {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.page = 1;
            this.getResults();
        },
        onClicks(item) {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.loading = true;

            axios.get(item + '&limit=10&name=' + this.search + '&cabang=' + this.searchCabang)
                .then(response => {
                    this.page = response.data.data.current_page;
                    this.posts = response.data.data.data;
                    this.pagination = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                });
            this.loading = false;
        },
        showdetails() {
            this.showdetail = this.showdetail ? false : true;
        },
        simpantipeharga() {
            this.rowharga.push(this.tipeharga);
            this.tipeharga = {
                'nama_tipe': '',
                'min_jumlah': null,
                'harga': 0,
            }
            console.log(this.rowharga);
        },
        deleteindextipeharga(a) {
            this.rowharga.splice(a, 1);
        },
        showdetailtipes() {
            this.showdetailtipe = this.showdetailtipe ? false : true;
        },
        showdetailtipeeditsw() {
            this.showdetailtipeedit = this.showdetailtipeedit ? false : true;
        },
        deleteindextipehargaedit(a) {
            this.rowhargaEdit.splice(a, 1);
        },
        simpantipehargaedit() {
            this.rowhargaEdit.push(this.tipehargaEdit);
            this.tipehargaEdit = {
                'nama_tipe': '',
                'min_jumlah': null,
                'harga': 0,
            }
            console.log(this.rowharga);
        },
         printLabelbutton() {
            let routeData = this.$router.resolve({
                    name: 'print.index',
                    query: {
                        total: this.totalLabel,
                        data: this.jnskode == 0 ? this.printBarcode : this.printItem,
                        nama: this.printNama,
                    }
                });

                window.open(routeData.href, '_blank');
        },
    }

}
</script>
